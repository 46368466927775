export default {
  root: {
    name: "/",
    displayName: "navigationRoutes.home",
  },
  routes: [
    {
      name: "users",
      displayName: "menu.users",
      meta: {
        icon: "fa-icon fa fa-users",
      },
    },
    {
      name: "organizations",
      displayName: "menu.organizations",
      meta: {
        icon: "fa-icon fa fa-institution",
      },
    },
    {
      name: "apps",
      displayName: "menu.applications",
      meta: {
        icon: "fa-icon fa fa-desktop",
      },
    },
    {
      name: "access",
      displayName: "menu.access",
      meta: {
        icon: "fa-icon fa fa-key",
      },
    },
    {
      name: "me",
      displayName: "menu.me",
      meta: {
        icon: "fa-icon fa fa-user",
      },
    },
  ],
};
