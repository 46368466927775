<template>
  <router-view />

  <vue-cookie-accept-decline
    :debug="false"
    :disableDecline="true"
    :showPostponeButton="false"
    elementId="cookiePanel"
    position="bottom"
    ref="cookiePanel"
    transitionName="slideFromBottom"
    type="bar"
  >
    <!-- Optional -->
    <template #message>
      {{ $t("topbar.cookies_text") }}
      <a href="https://cookiesandyou.com/" target="_blank">{{
        $t("topbar.cookies_text_more")
      }}</a>
    </template>

    <!-- Optional -->
    <template #acceptContent>{{ $t("topbar.cookies_button") }}</template>
  </vue-cookie-accept-decline>
</template>

<style lang="scss">
@import "~@/sass/main.scss";
#app {
  font-family: "Source Sans Pro", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

body {
  margin: 0;
  background: var(--va-background);
}
</style>
