/* eslint-disable indent */
import axios from "axios";

const url = process.env.NODE_ENV === "production" ? "/api/" : "/api/";
axios.defaults.withCredentials = true;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

export default {
  async loginGoogle(data) {
    return await axios
      .post(url + "public/user/login/google", { token: data })
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async login(data) {
    return await axios
      .post(url + "public/user/login", data)
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async login2FA(code) {
    return await axios
      .post(url + "public/user/login/2FA/" + code)
      .then((e) => {
        return { data: e.data, status: true };
      })
      .catch((e) => {
        return { data: e.response.data, status: false };
      });
  },
  async logout() {
    return await axios
      .post(url + "public/user/logout", {})
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async isLogged() {
    return await axios
      .get(url + "public/user/islogged")
      .then((e) => {
        // console.log('backed returned logged')
        return e.data;
      })
      .catch((e) => {
        // console.log('backed returned NOT logged')
        return false;
      });
  },
  async isInstantiated() {
    return await axios
      .get(url + "public/isinstantiated")
      .then((e) => {
        // console.log('backed returned installed')
        return true;
      })
      .catch((e) => {
        // console.log('backed returned NOT installed')
        return false;
      });
  },
  async instantiate(data) {
    return await axios
      .post(url + "public/instantiate", data)
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async sendResetPasswordEmail(data, lang) {
    return await axios
      .post(url + "public/sendResetPasswordEmail/" + lang, data)
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async getSetPasswordUser(id) {
    return await axios
      .get(url + "public/setPassword/" + id)
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async setPasswordUser(data) {
    return await axios
      .post(url + "public/setPassword", data)
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },

  async listOrganization() {
    return await axios
      .get(url + "organization")
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async addOrg(data) {
    return await axios
      .post(url + "organization/create", data)
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async removeOrg(id) {
    return await axios
      .post(url + "organization/" + id + "/remove")
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async editOrg(id, data) {
    return await axios
      .post(url + "organization/" + id + "/edit", data)
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },

  async listApp() {
    return await axios
      .get(url + "app")
      .then((e) => {
        for (let i = 0; i < e.data.length; i++) {
          if (e.data[i].enabled) {
            e.data[i].active = "Active";
          } else {
            e.data[i].active = "Inactive";
          }
        }
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async addApp(data) {
    return await axios
      .post(url + "app/create", data)
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async editApp(id, data) {
    return await axios
      .post(url + "app/" + id + "/edit", data)
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async regenApp(id) {
    return await axios
      .post(url + "app/" + id + "/regen")
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async removeApp(id) {
    return await axios
      .post(url + "app/" + id + "/remove")
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },

  async listUser() {
    return await axios
      .get(url + "user")
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async getUser() {
    return await axios
      .get(url + "user/me")
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async changeMyName(name) {
    return await axios
      .post(url + "user/me/edit/name/" + name)
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async changeMyAccess(data) {
    return await axios
      .post(url + "user/me/edit/access", data)
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async changeMyPassword(data) {
    return await axios
      .post(url + "user/me/edit/password", data)
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async new2FA() {
    return await axios
      .get(url + "user/me/2FA/new")
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async set2FA(code) {
    return await axios
      .post(url + "user/me/2FA/set/" + code)
      .then((e) => {
        return { data: e.data, status: true };
      })
      .catch((e) => {
        return { data: e.response.data, status: false };
      });
  },
  async clear2FA(code) {
    return await axios
      .post(url + "user/me/2FA/clear/" + code)
      .then((e) => {
        return { data: e.data, status: true };
      })
      .catch((e) => {
        return { data: e.response.data, status: false };
      });
  },

  async addUser(data, lang) {
    return await axios
      .post(url + "user/create/" + lang, data)
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async editUser(id, data) {
    return await axios
      .post(url + "user/" + id + "/edit", data)
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async disable2FA(id) {
    return await axios
      .post(url + "user/" + id + "/disable2FA")
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async removeUser(id, data) {
    return await axios
      .post(url + "user/" + id + "/remove", data)
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async removeUserGtw(email, data) {
    return await axios
      .post(url + "user/" + email + "/remove/gtwOnly", data)
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },

  async listAcc() {
    return await axios
      .get(url + "access")
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async addAcc(data) {
    return await axios
      .post(url + "access/create", data)
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async editAcc(id, data) {
    return await axios
      .post(url + "access/" + id + "/edit", data)
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async removeAcc(id) {
    return await axios
      .post(url + "access/" + id + "/remove")
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
};
