import { VuesticPlugin } from "vuestic-ui";
import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuesticGlobalConfig from "./services/vuestic-ui/global-config";
import Vidle from "v-idle-3";
import vue3GoogleLogin from "vue3-google-login";
import VueCookieAcceptDecline from "vue-cookie-accept-decline";

import backend from "./backend";

function storageAvailable(type) {
  let storage;
  try {
    storage = window[type];
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return storage;
  } catch (e) {
    return null;
  }
}

function getInitLang() {
  let init = "en";
  const available = ["en", "cz"];
  const local = storageAvailable("localStorage");
  if (local) {
    const fromStorage = local.getItem("EBS-admin-vuei18-lang");
    if (fromStorage) {
      if (available.indexOf(fromStorage) > -1) {
        return fromStorage;
      } else {
        local.removeItem("EBS-admin-vuei18-lang");
      }
    }
  }
  let browserLanguage = (
    window.navigator.language ||
    window.navigator.userLanguage ||
    window.navigator.browserLanguage
  ).toLowerCase();
  switch (browserLanguage) {
    case "cs":
      browserLanguage = "cz";
      break;
    case "zh-cn":
      browserLanguage = "cn";
      break;
    case "zh-tw":
      browserLanguage = "tw";
      break;
    default:
      break;
  }
  if (available.indexOf(browserLanguage) > -1) {
    init = browserLanguage;
  } else {
    browserLanguage = browserLanguage.split("-")[0];
    if (available.indexOf(browserLanguage) > -1) {
      init = browserLanguage;
    }
  }
  if (local) {
    local.setItem("EBS-admin-vuei18-lang", init);
  }
  return init;
}

router.beforeEach((to, from, next) => {
  store.commit("setLoading", true);
  if (to.meta && to.meta.ignoreAuth) {
    // console.log('Ignoring loggin')
    next();
  } else {
    // next()
    let isLogged = store.getters.isLogged;
    // console.log('Getting if logged: ' + isLogged)
    if (isLogged === null) {
      // console.log('calling API to check login')
      backend.isLogged().then((r) => {
        if (r !== false) {
          isLogged = true;
          store.commit("login", r);
          // console.log('Logged!')
          next();
        } else {
          isLogged = false;
          store.commit("logout");
          // console.log('NOT Logged!')
          next({
            path: "/auth/login",
          });
        }
      });
    } else {
      if (isLogged === true) {
        // console.log('Logged!')
        next();
      } else {
        // console.log('NOT Logged!')
        next({
          path: "/auth/login",
        });
      }
    }
  }
});

router.afterEach((to, from) => {
  store.commit("setLoading", false);
});

const i18nConfig = {
  locale: getInitLang(),
  fallbackLocale: "en",
  messages: {
    en: require("@/i18n/en.json"),
    cz: require("@/i18n/cs.json"),
    // cn: require('@/i18n/zh-cn.json'),
    // tw: require('@/i18n/zh-tw.json')
  },
};

const app = createApp(App);
app.use(Vidle);
// app.use(vue3GoogleLogin, {
//   clientId:
//     "550487851268-bri02btbgo7j2c9gmok7ohoibtfb785l.apps.googleusercontent.com",
// });
app.use(store);
app.use(router);
app.config.globalProperties.backend = backend;
app.use(createI18n(i18nConfig));
app.use(VuesticPlugin, vuesticGlobalConfig);
app.component("vue-cookie-accept-decline", VueCookieAcceptDecline);
app.mount("#app");
